<template>

    <header class="header">
        <a class="header__logo" href="#">{{ pageTitle }}</a>
        <div class="ham-menu" @click="changeVisibility()" :class="{ 'active': burgerVisibility }">
            <span class="ham-menu__item"></span>
            <span class="ham-menu__item"></span>
            <span class="ham-menu__item"></span>
        </div>
        
        <nav class="header__nav-wrap" :class="{ 'open': burgerVisibility }">
            <ul class="header__nav-list" @click="burgerVisibility = false">
                <li class="header__nav-item">
                    <router-link class="header__nav-link" to="/base">
                        Home
                    </router-link>
                </li>
                <li class="header__nav-item">
                    <router-link class="header__nav-link" to="/neighbourhood">
                        Neighbourhood
                    </router-link>
                </li>
                <li class="header__nav-item">
                    <router-link class="header__nav-link" to="/three">
                        Three
                    </router-link>
                </li>
                <li class="header__nav-item">
                    <router-link class="header__nav-link" to="/units">
                        Units
                    </router-link>
                </li>
                <li class="header__nav-item">
                    <router-link class="header__nav-link" to="/demo">
                        Demo
                    </router-link>
                </li>
            </ul>
        </nav>
    </header>

</template>

<script>
    export default {
        name: 'qoo-header',

        data() {
            return {
                pageTitle: '' ,
                burgerVisibility: false
            }
        },

        watch: {
            '$route' (to) {
                this.pageTitle = to.meta.title;
            }
        },

        methods: {
            changeVisibility() {
                this.burgerVisibility = !this.burgerVisibility;
            }
        },

        mounted() {
            this.pageTitle = this.$router.currentRoute._value.meta.title;
        }
    }
</script>

<style lang='scss'>
    @import 'Header';
</style>