import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "Error404",
            meta: { layout: "default", title: "Page introuvable" },
            component: lazyLoad('Errors/404'),
      },
      {
            path: '/',
            redirect: '/canvas',
      },
      {
            path: '/canvas',
            name: "canvas",
            component: lazyLoad('canvas/CanvasTest'),
      },
      {
            path: '/base',
            name: 'homePage',
            component: lazyLoad('Home'),
            meta: { layout: "default", title: "Home" , requiresAuth: true },
            props: true,
      },
      {
            path: '/neighbourhood',
            name: 'Neighbourhood-page',
            component: lazyLoad('NeighbourhoodComponent/Neighbourhood-page'),
            meta: { layout: "default", title: "Neighbourhood" , requiresAuth: true },
            props: true,
      },
      {
            path: '/units',
            name: 'units',
            component: lazyLoad('UnitsComponent/UnitsPage'),
            meta: { layout: "default", title: "Units" , requiresAuth: true },
            props: true,
      },
      {
            path: '/units/compare',
            name: 'unitsCompare',
            component: lazyLoad('UnitsComponent/Compare'),
            meta: { layout: "default", title: "UnitsCompare" , requiresAuth: true },
            props: true,
      },
      {
            path: '/demo',
            name: 'demo-page',
            component: lazyLoad('demo-page'),
            meta: { layout: "default", title: "DEMO" , requiresAuth: false },
            props: true,
      },
      {
            path: '/three',
            name: 'Three',
            component: lazyLoad('Three'),
            meta: { layout: "default", title: "Three" , requiresAuth: true },
      },
      {
            path: '/worksheet-page',
            name: 'worksheet-page',
            component: lazyLoad('Form/worksheet-page'),
            meta: { layout: "default", title: "WORKSHEET" , requiresAuth: true },
      },
      {
            path: '/test',
            name: 'test',
            component: lazyLoad('test'),
            meta: { layout: "default", title: "test" , requiresAuth: false },
      },
      {
            path: '/animation',
            name: 'animation',
            component: lazyLoad('animation'),
            meta: { layout: "default", title: "animation" , requiresAuth: false },
      },
      {
            path: '/sketchfab',
            name: 'sketchfab',
            component: lazyLoad('3d/3d'),
            meta: { layout: "default", title: "sketchfab" , requiresAuth: true },
      },
      {
            path: '/TestThree',
            name: 'TestThree',
            component: lazyLoad('TestThree'),
            meta: { layout: "default", title: "TestThree" , requiresAuth: true },
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

export default router;